<template lang="pug">
.q-pa-md.q-my-xl
  p.text-h4 Events to process
  q-markup-table.text-left
    thead
      tr.bg-blue-2
        //- th Time
        th Start Time
        th Site
        th Zones
        th Events
        th Time Window ({{processingMaxTime/60}}m)
        th Operator(s) in action
    tbody
      tr(v-for="(v, i) in dealerSitesWithEvents").cursor-pointer.non-selectable
        td {{new Date(v.timestamp * 1000).toLocaleString()}}
        td {{v.site_name}}
        td
          span(v-for="(vv, kk) in v.zones") {{kk}}
        td {{v.events.length}}
        td
          div(style="width:100px;height:20px;position:relative;").bg-grey-3
            div(
              style="top:0;left:0;height:100%;"
              :class="{'bg-amber-3': (timeNow - v.timestamp)/processingMaxTime <= 0.7, 'bg-red-3': (timeNow - v.timestamp)/processingMaxTime > 0.7}"
              :style="{width: `${Math.round((timeNow - v.timestamp)/processingMaxTime*100) > 100 ? 100 : Math.round((timeNow - v.timestamp)/processingMaxTime*100)}%`}"
            ).absolute
        td
          q-avatar.overlapping(size="40px")
            img(src="https://cdn.quasar.dev/img/avatar1.jpg")
          q-avatar.overlapping(style="left:-10px;" size="40px")
            img(:src="myProfilePictureUrl")

q-separator

.q-pa-md.q-my-xl
  p.text-h4 Logs
  q-markup-table.text-left
    thead
      tr.bg-blue-2
        th Index
        th ID
        th Time
        th Site
        th Camera
        th Zone
        th Type
        th Status
        th Processing
    tbody
      tr(v-for="(v, i) in dealerEvents").cursor-pointer.non-selectable
        td {{dealerEvents.length - i}}
        td {{v.event_id}}
        td {{new Date(v.timestamp * 1000).toLocaleString()}}
        td {{v.site_name}}
        td {{v.device_name}}
        td {{v.alarm_zone}}
        td {{v.type}}
        td {{v.status}}
        td [Elly]
</template>

<style scoped>
.overlapping {
  border: 2px solid white;
  position: relative;
}
</style>

<script>
export default {
  computed: {
    dealerId: function () {
      return this.$store.getters.myDealerId
    },
    dealerEvents: function () {
      return this.$store.getters.dealerEvents
    },
    dealerSitesWithEvents: function () {
      return this.$store.getters.dealerSitesWithEvents
    },
    myProfilePictureUrl: function () {
      return this.$store.getters.userMe.authProviderInfo.profilePictureUrl
    },
    statusGetDealerEvents: function () {
      return this.$store.getters.statusGetDealerEvents
    },
    statusGetUserMeDealer: function () {
      return this.$store.getters.statusGetUserMeDealer
    },
  },
  data: function () {
    return {
      loadingInterval: 1000 * 5, // seconds
      loadingRecentEvents$i: null,
      loadingEventTimeWindow: 60 * 2, // minutes
      processingMaxTime: 60 * 5, // minutes
      timeNow: 0,
    }
  },
  watch: {
    statusGetUserMeDealer: function (val) {
      if (val !== "successful") return
      this.startLoadingRecentEvents()
    },
  },
  mounted: function () {
    this.updateTimeNow()
    this.startLoadingRecentEvents()
  },
  beforeUnmount: function () {
    this.stopLoadingRecentEvents()
  },
  methods: {
    startLoadingRecentEvents: function () {
      this.stopLoadingRecentEvents()
      this.loadRecentEvents()
      this.loadingRecentEvents$i = setInterval(() => {
        this.loadRecentEvents()
      }, this.loadingInterval)
    },
    stopLoadingRecentEvents: function () {
      if (!this.loadingRecentEvents$i) return
      clearInterval(this.loadingRecentEvents$i)
      this.loadingRecentEvents$i = null
    },
    // 
    loadRecentEvents: function () {
      this.updateTimeNow()
      const beginTimestamp = Math.round(new Date().getTime()/1000) - this.loadingEventTimeWindow
      return this.getDealerEvents({beginTimestamp})
    },
    updateTimeNow: function () {
      this.timeNow = Math.round(new Date().getTime() / 1000)
    },
    // 
    getDealerEvents: function ({beginTimestamp}) {
      if (!this.dealerId) return
      if (this.statusGetDealerEvents === "requested") return
      return this.$store.dispatch("getDealerEvents", {dealerId: this.dealerId, beginTimestamp})
    },
  },
}
</script>
